import { HttpRequest, HttpErrorResponse, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { DatePipe } from '@angular/common';

export function logInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const snackBar = inject(MatSnackBar);
  const authService = inject(AuthService);
  const datePipe = inject(DatePipe);

  return next(req).pipe(
    catchError(event => {
      if (event instanceof HttpErrorResponse && event.status === 401) {
        authService.logout();
        snackBar.open('Unauthenticated. Please Login.', null, {
          duration: 5000,
          panelClass: 'error-toast'
        });
      } else if (event.status === 404) {
        snackBar.open('Resource not found.', null, {
          duration: 5000,
          panelClass: 'error-toast'
        });
      } else if (event.status === 403) {
        snackBar.open('Unauthorized.', null, {
          duration: 5000,
          panelClass: 'error-toast'
        });
      } else if (event.status === 408) {
        snackBar.open('Network timeout occurred.', null, {
          duration: 5000,
          panelClass: 'error-toast'
        });
      } else if (event.status === 400) {
        snackBar.open(`Something went wrong.`, null, {
          duration: 5000,
          panelClass: 'error-toast'
        });
      } else if (event.status === 410) {
        snackBar.open(
          `Freight options expired at ${datePipe.transform(event.error.expired_at, 'short')}. Please call the supplier.`,
          null,
          {
            duration: 5000,
            panelClass: 'error-toast'
          }
        );
      } else if (event.status === 500) {
        snackBar.open('Server Error Occurred.', null, {
          duration: 5000,
          panelClass: 'error-toast'
        });
      }
      return throwError(() => event);
    })
  );
}
