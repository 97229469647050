import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { APIService } from './shared/services/api.service';
import { AuthService } from './shared/services/auth.service';
import { tokenInterceptor } from './shared/interceptors/token.interceptor';
import { logInterceptor } from './shared/interceptors/log.interceptor';
import { multiPartInterceptor } from './shared/interceptors/multi-part.interceptor';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { CustomErrorHandler } from './shared/services/error-handler.service';
import { DatePipe } from '@angular/common';
export function initializeNewRelic() {
  return () => {
    if (environment.environment !== 'local') {
      const options = {
        init: environment.new_relic.init,
        info: environment.new_relic.info,
        loader_config: environment.new_relic.loader_config
      };

      new BrowserAgent(options);
    }
  };
}
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.environment !== 'local',
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    AuthService,
    APIService,
    DatePipe,
    provideHttpClient(withInterceptors([tokenInterceptor, multiPartInterceptor, logInterceptor])),
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeNewRelic
    }
  ]
})
export class AppModule {}
